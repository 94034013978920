import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Modules = loadable(() => import("../components/modules"));

const IndexPage = (props) => {
  return (
    <Layout>
      <div className="layout-padding-top"></div>
      <Modules {...props} />
    </Layout>
  )
}

export const Head = (props) => {
  const seo = props.pageContext.data.seo;
  return (
    <Seo title={seo?.title} description={seo?.metaDesc} />
  )
}

export default IndexPage